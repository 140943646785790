import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: null,
  school: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateCompany: (state, action) => {
      state.company = action.payload;
    },
    updateSchool: (state, action) => {
      state.school = action.payload;
    },
  },
});

export const { updateCompany, updateSchool } = companySlice.actions;
export default companySlice.reducer;
