import { createSlice } from "@reduxjs/toolkit";
import TokenService from "../services/token";

const user = TokenService.getUser();

const initialState = user
  ? {
      isLogin: true,
      user: user,
    }
  : {
      isLogin: false,
      user: null,
    };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.isLogin = true;
    },
    update: (state, action) => {
      state.user.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.isLogin = false;
    },
  },
});

export const { login, update, logout } = authSlice.actions;
export default authSlice.reducer;
