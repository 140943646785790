import { ToastContainer } from "react-toastify";
import { Suspense } from "react";
import { Flex, Image } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/zh-cn";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./urls";

const App = () => {
  moment.locale("zh-cn");
  return (
    <Suspense
      fallback={
        <Flex w="full" minH="100vh" align="center" justify="center">
          <Image boxSize={50} src="/loading.gif" />
        </Flex>
      }
    >
      <ToastContainer
        style={{ minWidth: "480px" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <AppRoutes />
    </Suspense>
  );
};

export default App;
