const zhMessages = {
  hello: "你好",
  "navbar.home": "主页",
  "navbar.courses": "课程",
  "navbar.challenge": "挑战",
  "navbar.community": "题库",
  Login: "登录",
  Signup: "注册",

  "accountmenu.dashboard": "概览",
  "accountmenu.profile": "个人信息",
  "accountmenu.logout": "退出登录",

  "account.password.change": "修改密码",
  "account.old.password": "原密码",
  "account.old.password.required": "请输入原密码",
  "account.new.password1": "新密码",
  "account.new.password1.required": "请输入新密码",
  "account.new.password2": "确认密码",
  "account.new.password2.required": "请再次输入新密码",

  "auth.login.head.title": "登录",
  "auth.login.no.account": "没有用户？",
  "auth.login.signup.link.text": "注册",
  "auth.login.username": "用户名",
  "auth.login.username.required": "请输入用户名",
  "auth.login.username.max.50": "用户名最多50个字符",
  "auth.login.password": "密码",
  "auth.login.password.required": "请输入密码",
  "auth.login.password.format":
    "密码必须包含大写字母、小写字母、数字以及特殊字符",
  "auth.login.forget.password": "忘记密码？",
  "auth.login.remember.password": "记住密码",
  "auth.login.submit.button.text": "登录",
  "auth.login.weixin": "使用微信登录",

  "auth.signup.head.text": "注册",
  "auth.signup.has.account": "已经有帐户？",
  "auth.signup.login.link.text": "登录",
  "auth.signup.email": "电子邮箱",
  "auth.signup.email.required": "请输入电子邮箱",
  "auth.signup.email.format": "请输入正确的电子邮箱格式",
  "auth.signup.submit.button.text": "下一步",
  "auth.signup.email.need.verify": "请输入收到的验证码",

  "auth.verify.check.email.code": "请检查邮箱获取验证码",
  "auth.verify.submit.button.text": "验证",
  "auth.verify.resend.button.text": "没收到？重新发送",

  "auth.register.email": "电子邮件",
  "auth.register.head.title": "注册",
  "auth.register.username": "用户名",
  "auth.register.username.required": "请输入用户名",
  "auth.register.username.max.50": "用户名最多50个字符",
  "auth.register.name": "姓名",
  "auth.register.name.required": "请输入姓名",
  "auth.register.name.max.50": "姓名最多50个字符",
  "auth.register.password": "密码",
  "auth.register.password.required": "请输入密码",
  "auth.register.password.format":
    "密码必须包含大写字母、小写字母、数字以及特殊字符",
  "auth.register.forget.password": "忘记密码？",
  "auth.register.submit.button.text": "完成注册",
  "auth.register.success": "注册成功，请登录",

  "auth.forgetpassword.emailormobile.required": "请输入电子邮件或手机号码",
  "auth.forgetpassword.emailormobile.format": "电子邮件或手机号码格式错误",
  "auth.forgetpassword.head.text": "忘记密码",
  "auth.forgetpassword.emailormobile": "请输入注册时的电子邮件或手机号码",
  "auth.forgetpassword.submit.button.text": "下一步",
  "auth.forgetpassword.password.required": "请输入密码",
  "auth.forgetpassword.password.format":
    "密码必须包含大写字母、小写字母、数字以及特殊字符",
  "auth.forgetpassword.success": "重置密码成功，请登录",
  "auth.forgetpassword.emailormobile.need.verify": "请输入验证码",
  "auth.forgetpassword.check.emailormobile.code":
    "请检查邮箱或手机短信获取验证码",
  "auth.forgetpassword.code": "验证码",
  "auth.forgetpassword.password1": "请输入密码",
  "auth.forgetpassword.password2": "请再次输入密码",
  "auth.forgetpassword.password.match": "两次输入的密码不一致",
  "auth.forgetpassword.resetpassword.button.text": "重置密码",
  "auth.forgetpassword.resend.button.text": "没收到？重新发送",

  "school.schools": "校区管理",
  "school.image": "图片",
  "school.name": "校名",
  "school.headmaster": "校长",
  "school.phone": "联系电话",
  "school.url": "网址",
  "school.location": "地址",
  "school.description": "备注",

  "school.name.required": "请输入学校名称",
  "school.name.max.20": "学校名称最多20个字符",
  "school.headmaster.required": "请选择学校校长",
  "school.image.required": "请上传学校照片",
  "school.description.max.1000": "学校描述最多1000个字符",

  "classroom.classrooms": "教室管理",
  "classroom.image": "图片",
  "classroom.image.required": "请上传教室图片",
  "classroom.name": "教室名称",
  "classroom.name.required": "请输入教室名称",
  "classroom.name.max.50": "教室名称最多50个字符",
  "classroom.school": "校区",
  "classroom.school.required": "请选择校区",
  "classroom.description": "备注",
  "classroom.description.max.1000": "备注最多1000个字符",

  "headmaster.headmasters": "校长管理",
  "headmaster.name": "姓名",
  "headmaster.username": "用户名",
  "headmaster.image": "头像",
  "headmaster.gender": "性别",
  "headmaster.gender.male": "男",
  "headmaster.gender.female": "女",
  "headmaster.email": "电子邮件",
  "headmaster.phone": "联系电话",
  "headmaster.description": "备注",
  "headmaster.change.password": "修改密码",

  "headmaster.name.required": "请输入校长姓名",
  "headmaster.name.max.20": "姓名最多20个字符",
  "headmaster.username.required": "请输入用户名",
  "headmaster.username.min.6": "用户名最少6个字符",
  "headmaster.username.max.20": "用户名最多20个字符",
  "headmaster.image.required": "请上传校长头像",
  "headmaster.email.error": "电子邮件格式错误",
  "headmaster.description.max.1000": "备注最多1000个字符",

  "headmaster.course.teachers": "授课老师管理",

  "course.categories": "课程类型",
  "course.category.name": "类型名称",
  "course.category.name.required": "请输入类型名称",
  "course.category.name.max.50": "类型名称最多50个字符",
  "course.category.slug": "课程Slug码",
  "course.category.image": "课程类型图片",
  "course.category.image.required": "请上传课程类型图片",
  "course.category.tixi": "课程类型体系",
  "course.category.tixi.program": "编程",
  "course.category.tixi.maker": "创客",
  "course.category.tixi.special": "特色",
  "course.category.tixi.other": "其他",
  "course.category.courses": "课程列表",
  "course.category.description": "详细描述",
  "course.category.description.max.1000": "课程类型详细描述最多1000个字符",

  "course.course": "课程",
  "course.name": "课程名称",
  "course.name.required": "请输入课程名称",
  "course.name.max.50": "课程名称最多50个字符",
  "course.slug": "课程slug",
  "course.image": "课程封面",
  "course.image.required": "请上传课程封面图片",
  "course.level": "课程级别",
  "course.price": "课程价格",
  "course.price.required": "请输入课程价格",
  "course.price.positive": "请输入正数",
  "course.duration": "课程周期(天)",
  "course.duration.required": "请输入课程周期",
  "course.duration.positive": "请输入正数",
  "course.teachers": "老师",
  "course.level_0": "入门",
  "course.level_1": "初级",
  "course.level_2": "中级",
  "course.level_3": "高级",
  "course.level_4": "特级",
  "course.category": "课程类别",
  "course.category.required": "请选择课程类别",
  "course.description": "详细描述",
  "course.description.max.1000": "详细描述最多1000个字符",

  "lesson.lesson": "课",
  "lesson.name": "课名",
  "lesson.name.required": "请输入课名",
  "lesson.name.max.50": "课名最多50个字符",
  "lesson.slug": "课slug",
  "lesson.image": "图片",
  "lesson.image.required": "请上传图片",
  "lesson.sid": "序号",
  "lesson.type": "类型",
  "lesson.type.desc": "讲解课",
  "lesson.type.exec": "练习课",
  "lesson.type.test": "测试课",
  "lesson.type.event": "活动课",
  "lesson.type.other": "其他课",
  "lesson.course": "所属课程",
  "lesson.course.required": "请选择课程",
  "lesson.description": "课程描述",
  "lesson.description.required": "请输入课程描述",
  "lesson.markdown": "课件(markdown格式)",
  "lesson.markdown.required": "请上传课件件",

  "teacher.teachers": "教师管理",
  "teacher.name": "教师姓名",
  "teacher.name.required": "请输入教师姓名",
  "teacher.name.max.50": "教师姓名最多50个字符",
  "teacher.username": "用户名",
  "teacher.username.required": "请输入用户名",
  "teacher.username.max.20": "用户名最多20个字符",
  "teacher.change.password": "修改密码",
  "teacher.password": "密码",
  "teacher.password.required": "请输入密码",
  "teacher.password.min.8": "密码最少8个字符",
  "teacher.image": "照片",
  "teacher.image.required": "请上传教师照片",
  "teacher.gender": "性别",
  "teacher.gender.male": "男",
  "teacher.gender.female": "女",
  "teacher.email": "电子邮件",
  "teacher.email.error": "请输入正确的电子邮件格式",
  "teacher.phone": "联系电话",
  "teacher.zxschool": "所属校区",
  "teacher.zxschool.required": "请选择校区",
  "teacher.university": "毕业学校",
  "teacher.university.max.100": "毕业学校最多100个字符",
  "teacher.education": "学历",
  "teacher.education.1": "专科",
  "teacher.education.2": "本科",
  "teacher.education.3": "硕士",
  "teacher.education.4": "博士",
  "teacher.education.5": "博士后",
  "teacher.education.6": "其他",
  "teacher.stars": "教师星级",
  "teacher.stars.1": "一星",
  "teacher.stars.2": "二星",
  "teacher.stars.3": "三星",
  "teacher.stars.4": "四星",
  "teacher.stars.5": "五星",
  "teacher.starts.required": "请输入教师星级",
  "teacher.base_salary": "基本工资",
  "teacher.base_salary.positive": "请输入正数",
  "teacher.description": "详细描述",
  "teacher.description.max.1000": "详细描述最多1000个字符",

  "forgetpassword.title": "忘记密码",
  "forgetpassword.email": "电子邮件",
  "forgetpassword.email.required": "请输入电子邮件",
  "forgetpassword.email.error": "请输入正确的电子邮件格式",
  "forgetpassword.submit.text": "忘记密码",
  "forgetpassword.success":
    "发送成功，你将收到电子邮件，点击邮件中的重置密码连接进行重置。",

  "resetpassword.title": "重置密码",
  "resetpassword.password1": "新密码",
  "resetpassword.password1.required": "请输入新密码",
  "resetpassword.password1.min.8": "密码至少8个字符",
  "resetpassword.password2": "再次输入",
  "resetpassword.password2.required": "请再次输入新密码",
  "resetpassword.password2.min.8": "密码至少8个字符",
  "resetpassword.password2.nomatch": "两次输入的密码不一致",
  "resetpassword.submit.text": "重置密码",
  "resetpassword.success": "重置成功",
};
export default zhMessages;
