import { createSlice } from "@reduxjs/toolkit";

const defaultProject = {
  id: null,
  name: "未命名",
  code: "",
  code_input: "",
  type: "C++",
  is_public: false,
};

const initialState = {
  codePanelSize: "50%",
  terminalPanelSize: "50%",
  editorFontSize: 16,
  terminalFontSize: 16,
  runResult: null,
  project: defaultProject,
};

const cppIdeSlice = createSlice({
  name: "cppide",
  initialState,
  reducers: {
    toggleCodePanelFullScreen: (state) => {
      if (state.codePanelSize === "50%") {
        state.codePanelSize = "100%";
        state.terminalPanelSize = "0%";
      } else {
        state.codePanelSize = "50%";
        state.terminalPanelSize = "50%";
      }
    },

    toggleTerminalPanelFullScreen: (state) => {
      if (state.terminalPanelSize === "50%") {
        state.codePanelSize = "0%";
        state.terminalPanelSize = "100%";
      } else {
        state.codePanelSize = "50%";
        state.terminalPanelSize = "50%";
      }
    },
    setEditorFontSize: (state, action) => {
      state.editorFontSize = action.payload;
    },
    setTerminalFontSize: (state, action) => {
      state.terminalFontSize = action.payload;
    },
    setRunResult: (state, action) => {
      state.runResult = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    initProject: (state) => {
      state.project = defaultProject;
    },
  },
});

export const {
  toggleCodePanelFullScreen,
  toggleTerminalPanelFullScreen,
  setEditorFontSize,
  setTerminalFontSize,
  setRunResult,
  setProject,
  initProject,
} = cppIdeSlice.actions;
export default cppIdeSlice.reducer;
