import { lazy } from "react";
import { Routes, Route } from "react-router-dom";

// Required
const LoginRequired = lazy(() => import("./components/LoginRequired"));
const CompanyHeadmasterRequired = lazy(() =>
  import("./apps/company/components/headmaster/HeadmasterRequired")
);
const CompanyOwnerRequired = lazy(() =>
  import("./apps/company/components/owner/CompanyOwnerRequired")
);
const TeacherRequired = lazy(() =>
  import("./apps/company/components/teacher/TeacherRequired")
);

// Layouts
const LandingLayout = lazy(() => import("./layouts/LandingLayout"));
const FullLayout = lazy(() => import("./layouts/FullLayout"));
const DashboardLayout = lazy(() => import("./layouts/DashboardLayout"));
const CompanyDashboardLayout = lazy(() =>
  import("./apps/company/layouts/CompanyDashboardLayout")
);

// Pages
const Home = lazy(() => import("./pages/Home"));
const CourseList = lazy(() => import("./pages/courses/CourseList"));
const CourseDetail = lazy(() => import("./pages/courses/CourseDetail"));
const BuyCourse = lazy(() => import("./pages/courses/BuyCourse"));
const ProjectList = lazy(() => import("./pages/projects/ProjectList"));
const ProjectDetail = lazy(() => import("./pages/projects/ProjectDetail"));
const Questions = lazy(() => import("./pages/Questions"));
const About = lazy(() => import("./pages/About"));

// Auth
const Login = lazy(() => import("./pages/auth/Login"));
const WXLogin = lazy(() => import("./pages/auth/WXLogin"));
const WXLoginProcess = lazy(() => import("./pages/auth/WXLoginProcess"));
const Logout = lazy(() => import("./pages/auth/Logout"));

// Student dashboard
const Dashboard = lazy(() => import("./pages/account/Dashboard"));
const Profile = lazy(() => import("./pages/account/Profile"));
const MyCourseList = lazy(() => import("./pages/account/MyCourseList"));
const MyCourseDetail = lazy(() => import("./pages/account/MyCourseDetail"));
const StudyLesson = lazy(() => import("./pages/account/StudyLesson"));
const MyProjectList = lazy(() => import("./pages/account/MyProjects"));
const MyQuestions = lazy(() => import("./pages/account/MyQuestions"));
const MyClassTimeList = lazy(() => import("./pages/account/MyClasstimes"));
const MyExercises = lazy(() => import("./pages/account/MyExercises"));

// Company
const CompanyOwnerDashboard = lazy(() =>
  import("./apps/company/pages/owner/Dashboard")
);
const CompanyEdit = lazy(() =>
  import("./apps/company/pages/owner/CompanyEdit")
);
// 校长CRUD
const CompanyHeadmasterList = lazy(() =>
  import("./apps/company/pages/owner/headmaster/List")
);
const CompanyHeadmasterCreate = lazy(() =>
  import("./apps/company/pages/owner/headmaster/Create")
);
const CompanyHeadmasterEdit = lazy(() =>
  import("./apps/company/pages/owner/headmaster/Edit")
);
// 教师CRUD
const CompanyTeacherList = lazy(() =>
  import("./apps/company/pages/owner/teacher/List")
);
const CompanyTeacherCreate = lazy(() =>
  import("./apps/company/pages/owner/teacher/Create")
);
const CompanyTeacherEdit = lazy(() =>
  import("./apps/company/pages/owner/teacher/Edit")
);
// 校区CRUD
const CompanySchoolList = lazy(() =>
  import("./apps/company/pages/owner/school/List")
);
const CompanySchoolCreate = lazy(() =>
  import("./apps/company/pages/owner/school/Create")
);
const CompanySchoolEdit = lazy(() =>
  import("./apps/company/pages/owner/school/Edit")
);

// 机构课程
const CompanyCourseList = lazy(() =>
  import("./apps/company/pages/owner/course/List")
);
const CompanyCourseDetail = lazy(() =>
  import("./apps/company/pages/owner/course/Detail")
);

// 校长
const CompanyHeadmasterDashboard = lazy(() =>
  import("./apps/company/pages/headmaster/Dashboard")
);

const SchoolEdit = lazy(() =>
  import("./apps/company/pages/headmaster/SchoolEdit")
);

// 学生CRUD
const SchoolStudentList = lazy(() =>
  import("./apps/company/pages/headmaster/student/List")
);
const SchoolStudentCreate = lazy(() =>
  import("./apps/company/pages/headmaster/student/Create")
);
const SchoolStudentEdit = lazy(() =>
  import("./apps/company/pages/headmaster/student/Edit")
);

// 班级CRUD
const SchoolGlassList = lazy(() =>
  import("./apps/company/pages/headmaster/glass/List")
);
const SchoolGlassCreate = lazy(() =>
  import("./apps/company/pages/headmaster/glass/Create")
);
const SchoolGlassEdit = lazy(() =>
  import("./apps/company/pages/headmaster/glass/Edit")
);

// 校长排课
const HeadmasterClasstimeList = lazy(() =>
  import("./apps/company/pages/headmaster/classtime/List")
);
const HeadmasterClasstimeCreate = lazy(() =>
  import("./apps/company/pages/headmaster/classtime/Create")
);
const HeadmasterClasstimeEdit = lazy(() =>
  import("./apps/company/pages/headmaster/classtime/Edit")
);

// 教师
const TeacherDashboard = lazy(() =>
  import("./apps/company/pages/teacher/Dashboard")
);
const TeacherCourseList = lazy(() =>
  import("./apps/company/pages/teacher/course/List")
);
const TeacherCourseCreate = lazy(() =>
  import("./apps/company/pages/teacher/course/Create")
);
const TeacherCourseEdit = lazy(() =>
  import("./apps/company/pages/teacher/course/Edit")
);
const TeacherUserLessonList = lazy(() =>
  import("./apps/company/pages/teacher/userlesson/List")
);
const TeacherUserLessonEdit = lazy(() =>
  import("./apps/company/pages/teacher/userlesson/Edit")
);
const UserLessonExerciseList = lazy(() =>
  import("./apps/company/pages/teacher/lessonexercise/List")
);
const UserLessonExerciseEdit = lazy(() =>
  import("./apps/company/pages/teacher/lessonexercise/Edit")
);
const TeacherClasstimeList = lazy(() =>
  import("./apps/company/pages/teacher/classtime/List")
);
const TeacherClasstimeEdit = lazy(() =>
  import("./apps/company/pages/teacher/classtime/Edit")
);

// IDE
const CppIDE = lazy(() => import("./pages/ide/cpp/CppIDE"));
const PythonIDE = lazy(() => import("./pages/ide/python/PythonIDE"));
const ScratchIDE = lazy(() => import("./pages/ide/scratch/ScratchIDE"));

// 题库
const QuestionList = lazy(() =>
  import("./apps/questionbank/pages/question/List")
);
const QuestionDetail = lazy(() =>
  import("./apps/questionbank/pages/question/Detail")
);

const TeacherQuestionList = lazy(() =>
  import("./apps/questionbank/pages/question/teacher/List")
);
const TeacherQuestionCreate = lazy(() =>
  import("./apps/questionbank/pages/question/teacher/Create")
);
const TeacherQuestionEdit = lazy(() =>
  import("./apps/questionbank/pages/question/teacher/Edit")
);

// Not found
const NotFound = lazy(() => import("./pages/NotFound"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<LandingLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<CourseList />} />
        <Route path="/courses/:id" element={<CourseDetail />} />
        <Route path="/projects" element={<ProjectList />} />
        <Route path="/projects/:id" element={<ProjectDetail />} />

        <Route path="/questions" element={<QuestionList />} />
        <Route path="/questions/:id" element={<QuestionDetail />} />

        <Route path="/about" element={<About />} />
      </Route>

      <Route element={<FullLayout />}>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/wxlogin" element={<WXLogin />} />
        <Route path="/auth/wxloginprocess" element={<WXLoginProcess />} />
      </Route>

      <Route
        element={
          <LoginRequired>
            <DashboardLayout />
          </LoginRequired>
        }
      >
        <Route path="/account/courses" element={<MyCourseList />} />
        <Route path="/account/courses/:id" element={<MyCourseDetail />} />
        <Route path="/account/projects" element={<MyProjectList />} />
        <Route path="/account/dashboard" element={<Dashboard />} />
        <Route path="/account/profile" element={<Profile />} />
        <Route path="/account/questions" element={<MyQuestions />} />
        <Route path="/account/classtimes" element={<MyClassTimeList />} />
        <Route path="/account/exercises" element={<MyExercises />} />
      </Route>

      <Route
        element={
          <CompanyOwnerRequired>
            <CompanyDashboardLayout />
          </CompanyOwnerRequired>
        }
      >
        <Route path="/company/dashboard" element={<CompanyOwnerDashboard />} />
        <Route path="/company/edit" element={<CompanyEdit />} />
        <Route
          path="/company/headmasters"
          element={<CompanyHeadmasterList />}
        />
        <Route
          path="/company/headmasters/create"
          element={<CompanyHeadmasterCreate />}
        />
        <Route
          path="/company/headmasters/:id/edit"
          element={<CompanyHeadmasterEdit />}
        />
        <Route path="/company/teachers" element={<CompanyTeacherList />} />
        <Route
          path="/company/teachers/create"
          element={<CompanyTeacherCreate />}
        />
        <Route
          path="/company/teachers/:id/edit"
          element={<CompanyTeacherEdit />}
        />
        <Route path="/company/schools" element={<CompanySchoolList />} />
        <Route
          path="/company/schools/create"
          element={<CompanySchoolCreate />}
        />
        <Route
          path="/company/schools/:id/edit"
          element={<CompanySchoolEdit />}
        />
        <Route path="/company/courses" element={<CompanyCourseList />} />
        <Route path="/company/courses/:id" element={<CompanyCourseDetail />} />
      </Route>

      {/* 校长 */}
      <Route
        element={
          <CompanyHeadmasterRequired>
            <CompanyDashboardLayout />
          </CompanyHeadmasterRequired>
        }
      >
        <Route
          path="/headmaster/dashboard"
          element={<CompanyHeadmasterDashboard />}
        />
        <Route path="/school/edit" element={<SchoolEdit />} />
        <Route path="/headmaster/students" element={<SchoolStudentList />} />
        <Route
          path="/headmaster/students/create"
          element={<SchoolStudentCreate />}
        />
        <Route
          path="/headmaster/students/:id/edit"
          element={<SchoolStudentEdit />}
        />

        <Route path="/headmaster/classes" element={<SchoolGlassList />} />
        <Route
          path="/headmaster/classes/create"
          element={<SchoolGlassCreate />}
        />
        <Route
          path="/headmaster/classes/:id/edit"
          element={<SchoolGlassEdit />}
        />

        <Route
          path="/headmaster/classtimes"
          element={<HeadmasterClasstimeList />}
        />
        <Route
          path="/headmaster/classtimes/create"
          element={<HeadmasterClasstimeCreate />}
        />
        <Route
          path="/headmaster/classtimes/:id/edit"
          element={<HeadmasterClasstimeEdit />}
        />
      </Route>

      {/* 教师 */}
      <Route
        element={
          <TeacherRequired>
            <CompanyDashboardLayout />
          </TeacherRequired>
        }
      >
        <Route path="/teacher/dashboard" element={<TeacherDashboard />} />
        <Route path="/teacher/courses" element={<TeacherCourseList />} />
        <Route
          path="/teacher/courses/create"
          element={<TeacherCourseCreate />}
        />
        <Route
          path="/teacher/courses/:id/edit"
          element={<TeacherCourseEdit />}
        />
        <Route
          path="/teacher/userlessons"
          element={<TeacherUserLessonList />}
        />
        <Route
          path="/teacher/userlessons/:id/edit"
          element={<TeacherUserLessonEdit />}
        />
        <Route
          path="/teacher/userexercises"
          element={<UserLessonExerciseList />}
        />
        <Route
          path="/teacher/userexercises/:id/edit"
          element={<UserLessonExerciseEdit />}
        />
        <Route path="/teacher/classtimes" element={<TeacherClasstimeList />} />
        <Route
          path="/teacher/classtimes/:id/edit"
          element={<TeacherClasstimeEdit />}
        />
        <Route path="/teacher/questions" element={<TeacherQuestionList />} />
        <Route
          path="/teacher/questions/create"
          element={<TeacherQuestionCreate />}
        />
        <Route
          path="/teacher/questions/:id/edit"
          element={<TeacherQuestionEdit />}
        />
      </Route>

      <Route
        element={
          <LoginRequired>
            <FullLayout />
          </LoginRequired>
        }
      >
        <Route path="/account/lesson/:id" element={<StudyLesson />}></Route>
      </Route>

      <Route
        element={
          <LoginRequired>
            <LandingLayout />
          </LoginRequired>
        }
      >
        <Route path="/buy/course" element={<BuyCourse />} />
      </Route>

      <Route
        path="/cpp"
        element={
          <LoginRequired>
            <CppIDE />
          </LoginRequired>
        }
      />
      <Route
        path="/cpp/:id"
        element={
          <LoginRequired>
            <CppIDE />
          </LoginRequired>
        }
      />

      <Route
        path="/python"
        element={
          <LoginRequired>
            <PythonIDE />
          </LoginRequired>
        }
      />
      <Route
        path="/python/:id"
        element={
          <LoginRequired>
            <PythonIDE />
          </LoginRequired>
        }
      />

      <Route
        path="/scratch"
        element={
          <LoginRequired>
            <ScratchIDE />
          </LoginRequired>
        }
      />
      <Route
        path="/scratch/:id"
        element={
          <LoginRequired>
            <ScratchIDE />
          </LoginRequired>
        }
      />

      <Route path="/auth/logout" element={<Logout />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
