import { configureStore } from "@reduxjs/toolkit";

import langReducer from "./reducers/langSlice";
import authReducer from "./reducers/authSlice";
import cppIdeReducer from "./reducers/cppIdeSlice";
import pythonIdeReducer from "./reducers/pythonIdeSlice";
import companyReducer from "./apps/company/reducers";

const store = configureStore({
  reducer: {
    lang: langReducer,
    auth: authReducer,
    cppIde: cppIdeReducer,
    pythonIde: pythonIdeReducer,
    company: companyReducer,
  },
});

export default store;
