const enMessages = {
  hello: "Hello",
  "navbar.home": "Home",
  "navbar.courses": "Courses",
  "navbar.challenge": "Challenge",
  "navbar.community": "Community",
  Login: "Login",
  Signup: "Signup",

  "accountmenu.dashboard": "Dashboard",
  "accountmenu.profile": "Profile",
  "accountmenu.logout": "Logout",

  "account.password.change": "修改密码",
  "account.old.password": "原密码",
  "account.old.password.required": "请输入原密码",
  "account.new.password1": "新密码",
  "account.new.password1.required": "请输入新密码",
  "account.new.password2": "确认密码",
  "account.new.password2.required": "请再次输入新密码",

  "login.head_title": "Login",
  "login.no_account": "No account ?",
  "login.signup": "Sign Up",
  "login.username": "Username",
  "login.username_required": "Please input username",
  "login.password": "Password",
  "login.password_required": "Please input password",
  "login.forgetpassword": "Forget password ?",
  "login.button_text": "Login",

  "school.schools": "Schools",
  "school.image": "Image",
  "school.name": "Name",
  "school.headmaster": "Headmaster",
  "school.phone": "Phone",
  "school.url": "URL",
  "school.location": "Location",
  "school.description": "Description",

  "school.name.required": "Pleae input school name",
  "school.name.max.20": "School name max 20 characters",
  "school.headmaster.required": "Please choose a headmaster",
  "school.image.required": "Please upload the school image",
  "school.description.max.1000": "School description max 1000 characters",

  "headmaster.headmasters": "Headmasters",
  "headmaster.name": "Name",
  "headmaster.username": "Username",
  "headmaster.image": "Avatar",
  "headmaster.gender": "Gender",
  "headmaster.gender.male": "Male",
  "headmaster.gender.female": "Female",
  "headmaster.email": "Email",
  "headmaster.phone": "Phone",
  "headmaster.description": "Description",

  "headmaster.name.required": "Name required",
  "headmaster.name.max.20": "Name max 20 charactors",
  "headmaster.username.required": "Username required",
  "headmaster.username.min.6": "Username min 6 charactors",
  "headmaster.username.max.20": "Username max 20 charactors",
  "headmaster.image.required": "Image required",
  "headmaster.email.error": "Wrong email",
  "headmaster.description.max.1000": "Description max 1000 charactors",

  "course.categories": "课程类型",
  "course.category.name": "类型名称",
  "course.category.name.required": "请输入类型名称",
  "course.category.name.max.50": "类型名称最多50个字符",
  "course.category.slug": "课程Slug码",
  "course.category.image": "课程类型图片",
  "course.category.image.required": "请上传课程类型图片",
  "course.category.tixi": "课程类型体系",
  "course.category.tixi.program": "编程",
  "course.category.tixi.maker": "创客",
  "course.category.tixi.special": "特色",
  "course.category.tixi.other": "其他",
  "course.category.courses": "课程列表",
  "course.category.description": "课程类型详细描述",
  "course.category.description.max.1000": "课程类型详细描述最多1000个字符",

  "course.course": "课程",
  "course.name": "课程名称",
  "course.name.required": "请输入课程名称",
  "course.name.max.50": "课程名称最多50个字符",
  "course.slug": "课程slug",
  "course.image": "课程封面",
  "course.image.required": "请上传课程封面图片",
  "course.level": "课程级别",
  "course.level_0": "入门",
  "course.level_1": "初级",
  "course.level_2": "中级",
  "course.level_3": "高级",
  "course.level_4": "特级",
  "course.category": "课程类别",
  "course.category.required": "请选择课程类别",
  "course.description": "详细描述",
  "course.description.max.1000": "详细描述最多1000个字符",

  "lesson.lesson": "课",
  "lesson.name": "课名",
  "lesson.name.required": "请输入课名",
  "lesson.name.max.50": "课名最多50个字符",
  "lesson.slug": "课slug",
  "lesson.sid": "序号",
  "lesson.image": "图片",
  "lesson.image.required": "请上传图片",
  "lesson.type": "类型",
  "lesson.type.desc": "讲解课",
  "lesson.type.exec": "练习课",
  "lesson.type.test": "测试课",
  "lesson.type.event": "活动课",
  "lesson.type.other": "其他课",
  "lesson.course": "所属课程",
  "lesson.course.required": "请选择课程",
  "lesson.description": "课程内容",
  "lesson.description.required": "请输入课程内容",

  "teacher.teachers": "教师",
  "teacher.name": "教师姓名",
  "teacher.name.required": "请输入教师姓名",
  "teacher.name.max.50": "教师姓名最多50个字符",
  "teacher.username": "用户名",
  "teacher.username.required": "请输入用户名",
  "teacher.username.max.20": "用户名最多20个字符",
  "teacher.image": "照片",
  "teacher.image.required": "请上传教师照片",
  "teacher.gender": "性别",
  "teacher.email": "电子邮件",
  "teacher.email.error": "请输入正确的电子邮件格式",
  "teacher.phone": "联系电话",
  "teacher.zxschool": "所属校区",
  "teacher.zxschool.required": "请选择校区",
  "teacher.university": "毕业学校",
  "teacher.university.max.100": "毕业学校最多100个字符",
  "teacher.education": "学历",
  "teacher.education.1": "专科",
  "teacher.education.2": "本科",
  "teacher.education.3": "硕士",
  "teacher.education.4": "博士",
  "teacher.education.5": "博士后",
  "teacher.education.6": "其他",
  "teacher.stars": "教师星级",
  "teacher.stars.1": "一星",
  "teacher.stars.2": "二星",
  "teacher.stars.3": "三星",
  "teacher.stars.4": "四星",
  "teacher.stars.5": "五星",
  "teacher.starts.required": "请输入教师星级",
  "teacher.base_salary": "基本工资",
  "teacher.base_salary.positive": "请输入正数",
  "teacher.description": "详细描述",
  "teacher.description.max.1000": "详细描述最多1000个字符",
};
export default enMessages;
